<style lang="less">
// .case-center-page {}
</style>
<template>
  <div class="case-center-page">
    <div class="page-title">案例中心列表</div>
    <QueryOperator>
      <template slot="left">
        <a-button @click="addCase" icon="plus" type="primary">新建</a-button>
      </template>
      <template slot="right">
        <a-input allowClear class="query-action" v-model="queryParam.keyword" placeholder="案例标题"></a-input>
        <a-select @change="searchData" allowClear class="query-action" v-model="queryParam.type" placeholder="选择所属类别">
          <a-select-option v-for="item in caseTypeList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
        </a-select>
        <a-range-picker style="width: 225px;" v-model="rangeData" @change="searchData" class="query-action" />
        <!-- <a-input allowClear class="query-action" v-model="queryParam.company" placeholder="所属公司"></a-input> -->
        <a-button type="primary" @click="searchData">查询</a-button>
      </template>
    </QueryOperator>
    <a-card>
      <a-table
        :loading="loading"
        :columns="columns" 
        :data-source="dataSource"
        :pagination="ipagination"
        @change="handleTableChange">
        <span slot="type" slot-scope="text, record">
          {{ typeFilter(record) }}
        </span>
        <span slot="state" slot-scope="text, record">
          {{ stateFilter(record) }}
        </span>
        <template v-slot:weight="weight, record">
          <a-input-number :value="record.weight" @blur="handleChangeWeight($event, record)"></a-input-number>
        </template>
        <span slot="action" slot-scope="text, record">
          <a @click="editCase(record)">编辑</a>
          <a-divider type="vertical" />
          <a-popconfirm title="确定删除吗?" @confirm="() => delCase(record)">
            <a class="error">删除</a>
          </a-popconfirm>
        </span>
      </a-table>
    </a-card>
    <CaseEdit :fullscreen="true" @save="searchData" ref="caseEdit" />
  </div>
</template>

<script>
import moment from 'moment'
import QueryOperator from '@/components/Operator/QueryOperator'
import CaseEdit from './components/CaseEdit'
import { 
  getCaseList,
  getCaseTypeList,
  getCaseStatusList,
  setCaseTop,
  delCase,
  changeCaseWeight
} from '@/api'
import {
  isNumber
} from '@/utils/util'

const columns = [
  {
    title: 'ID',
    dataIndex: 'id'
  },
  {
    title: '所属版块',
    dataIndex: 'type',
    scopedSlots: { customRender: 'type' }
  },
  {
    title: '案例标题',
    dataIndex: 'title'
  },
  {
    title: '公司',
    dataIndex: 'company'
  },
  {
    title: '状态',
    dataIndex: 'state',
    scopedSlots: { customRender: 'state' }
  },
  {
    title: '发布时间',
    dataIndex: 'releaseDate'
  },
  {
    title: '权重',
    dataIndex: 'weight',
    scopedSlots: { customRender: 'weight' }
  },
  {
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  },
]
export default {
  components: {
    QueryOperator,
    CaseEdit
  },
  data () {
    return {
      loading: false,
      // 查询参数
      queryParam: {
        // 标题
        keyword: undefined,
        // 类别
        type: undefined,
        // 开始时间
        start: '',
        // 结束时间
        end: ''
      },
      // 默认时间
      rangeData: [],
      columns,
      dataSource: [],
      // 分页
      ipagination: {
        current: 1,
        pageSize: 10,
        total: 0
      },
      // 案例类型列表
      caseTypeList: [],
      // 案例状态列表
      caseStatusList: []
    }
  },
  created () {
    // 案例类型列表
    this.getCaseTypeList()
    // 案例状态列表
    this.getCaseStatusList()
    // 获取案例列表
    this.getCaseList()
  },
  methods: {
    handleChangeWeight (e, record) { // 修改权重
      const action = e.target.value
      if (isNumber(action)) {
        if (record.weight !== +action) {
          record.weight = action
          changeCaseWeight({
            id: record.id,
            action
          })
            .then(() => {
              this.getCaseList()
            })
        }
      } else {
        record.weight = ''
      }
    },
    // 案例类型列表
    getCaseTypeList () {
      getCaseTypeList().then(data => {
        if (data.code === 200) {
          this.caseTypeList = data.data
        }
      })
    },
    // 案例状态列表
    getCaseStatusList () {
      getCaseStatusList().then(data => {
        if (data.code === 200) {
          this.caseStatusList = data.data
        }
      })
    },
    // 获取类型显示
    typeFilter (record) {
      const type = this.caseTypeList.filter(item => item.id === record.type)
      if (type.length > 0) return type[0].name
      return ''
    },
    // 获取状态显示
    stateFilter (record) {
      const state = this.caseStatusList.filter(item => item.id === record.state)
      if (state.length > 0) return state[0].name
      return ''
    },
    // 获取案例列表
    getCaseList () {
      if (this.rangeData.length > 0) {
        this.queryParam.start = moment(this.rangeData[0]).format('YYYY-MM-DD')
        this.queryParam.end = moment(this.rangeData[1]).format('YYYY-MM-DD')
      } else {
        this.queryParam.start = ''
        this.queryParam.end = ''
      }
      let params = {
        page: this.ipagination.current,
        size: this.ipagination.pageSize
      }
      params = { ...params, ...this.queryParam }
      // 清除空字段
      for (const k in params) {
        if (!params[k] && String(params[k]) !== '0') {
          delete params[k]
        }
      }
      this.loading = true
      getCaseList(params).then(data => {
        if (data.code === 200) {
          data.data.list.forEach(item => {
            item.key = item.id
          })
          this.dataSource = data.data.list
          this.ipagination.total = data.data.total
        }
      }).finally(() => {
        this.loading = false
      })
    },
    // 分页选择
    handleTableChange (pagination) {
      this.ipagination = pagination
      // 获取案例列表
      this.getCaseList()
    },
    // 新闻置顶
    setCaseTop (item) {
      const params = {
        // 0 取消置顶 1 置顶
        action: item.weight ? 0 : 1,
        id: item.id
      }
      setCaseTop(params).then(data => {
        if (data.code === 200) {
          // 获取案例列表
          this.getCaseList()
          this.$message.success('操作成功！')
        } else {
          this.$message.error(data.message || '操作失败！')
        }
      })
    },
    // 案例删除
    delCase (item) {
      delCase(item).then(data => {
        if (data.code === 200) {
          // 获取案例列表
          this.getCaseList()
          this.$message.success('操作成功！')
        } else {
          this.$message.error(data.message || '操作失败！')
        }
      })
    },
    // 查询数据
    searchData () {
      this.ipagination.current = 1
      // 获取案例列表
      this.getCaseList()
    },
    // 时间变化
    changeDate (date) {
      this.rangeData = date
    },
    editCase (record) { // 编辑案例
      this.$refs.caseEdit.handleEdit(record, '编辑案例')
      this.$refs.caseEdit.caseTypeList = this.caseTypeList
    },
    addCase () { // 添加案例
      this.$refs.caseEdit.handleAdd({}, '添加案例')
      this.$refs.caseEdit.caseTypeList = this.caseTypeList
    }
  }
}
</script>
