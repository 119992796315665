<template>
  <a-drawer
    :title="title"
    wrapClassName="form-edit-drawer"
    placement="right"
    :visible="visible"
    :width="drawerWidth"
    :destroyOnClose="false"
    :body-style="{ paddingBottom: '80px' }"
    @close="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-form-model ref="form" :labelCol="labelCol" :wrapperCol="wrapperCol" :model="formData" :rules="validatorRules">
          <a-form-model-item prop="type" label="案例版块">
            <a-select placeholder="案例版块" v-model="formData.type">
              <a-select-option v-for="item in caseTypeList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item prop="title" label="案例标题">
            <a-input placeholder="请输入标题" v-model="formData.title" />
          </a-form-model-item>
          <a-form-model-item prop="company" label="案例公司">
            <a-input placeholder="请输入公司" v-model="formData.company" />
          </a-form-model-item>
          <a-form-model-item label="案例URL">
            <a-input placeholder="请输入URL" v-model="formData.url" />
          </a-form-model-item>
          <a-form-model-item prop="content" label="公司内容">
            <TinymceEditor v-model="formData.content" />
          </a-form-model-item>
      </a-form-model>
    </a-spin>
    <div
      class="drawer-button"
    >
      <a-button :style="{ marginRight: '8px' }" @click="handleCancel">
        取消
      </a-button>
      <a-button type="primary" @click="handleOk">
        保存
      </a-button>
    </div>
  </a-drawer>
</template>

<script>
import pick from 'lodash.pick'
import editFormModelMixin from '@/mixins/editFormModelMixin'
import TinymceEditor from '@/components/TinymceEditor'
import {
  aliyunOssUrl,
  aliyunUploadUrl,
  saveCase
} from '@/api'

export default {
  mixins: [editFormModelMixin],
  components: {
    TinymceEditor
  },
  data () {
    return {
      formData: {
        id: '',
        type: undefined,
        title: '',
        company: '',
        url: '',
        content: ''
      },
      // 案例
      caseObj: {},
      content: '',
      // 案例类型列表
      caseTypeList: []
    }
  },
  computed: {
    validatorRules: function () {
      return {
        type: [
          {
            required: true,
            message: '请选择案例版块'
          }
        ],
        title: [
          {
            required: true,
            message: '请输入标题'
          }
        ],
        company: [
          {
            required: true,
            message: '请输入公司'
          }
        ],
        content: [
          {
            required: true,
            message: '请选择内容'
          }
        ],
      }
    }
  },
  methods: {
    handleSave () {
      const formData = { ...this.formData }
      formData.articleId = formData.articleId || 1000
      return saveCase(formData)
    }
  }
}
</script>

